import * as React from 'react';
import styled from 'styled-components';

const Items = styled.div`
  margin-top: -70px;
`;

const Item = styled.div`
  height: 480px;
  margin-bottom: ${({ theme }) => theme.spaces[4]};
  margin-left: -${({ theme }) => theme.spaces[5]};
  margin-right: -${({ theme }) => theme.spaces[5]};
  position: relative;
  background-size: cover;
  background-position: center;

  ${({ theme }) => theme.media.sm`
    height: 100vh;
    margin-left: -${theme.spaces[5]};
    margin-right: -${theme.spaces[5]};
  `};
`;

const Content = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 140px;
  width: 100%;
  background: linear-gradient(0deg, #000, transparent);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding-left: ${({ theme }) => theme.spaces[5]};
  padding-right: ${({ theme }) => theme.spaces[5]};
  font-family: ${({ theme }) => theme.fonts.en};
  letter-spacing: 0.02em;
  text-indent: 0.01em;
`;

const Title = styled.h1`
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.fontSizes[4]};
`;

const Desc = styled.p`
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.fontSizes[0]};
`;

export interface CoverProps {
  items: CoverItemProps[];
}

interface CoverItemProps {
  title: string | React.ReactNode;
  desc: string | React.ReactNode;
  imagePath: string;
}

const Cover = ({ items }: CoverProps) => {
  return (
    <Items>
      {items.map((item, i) => (
        <Item key={i.toString()} style={{ backgroundImage: `url(${item.imagePath})` }}>
          <Content>
            <Title>{item.title}</Title>
            <Desc>{item.desc}</Desc>
          </Content>
        </Item>
      ))}
    </Items>
  );
};

export default Cover;
