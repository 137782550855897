import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import useSiteMetadata from '../hooks/useSiteMetadata';
import Layout from '../components/templates/Layout';
import Seo from '../components/parts/Seo';
import AboutUs from '../components/organisms/AboutUs';
import FeaturedWorks from '../components/organisms/FeaturedWorks';
import CompanyProfile from '../components/organisms/CompanyProfile';
import ContactUs from '../components/organisms/ContactUs';

const IndexPage = () => {
  const { description } = useSiteMetadata();
  const data = useStaticQuery(graphql`
    query {
      ultraJapan: file(relativePath: { eq: "ultra-japan.jpg" }) {
        childImageSharp {
          fluid(quality: 80) {
            originalImg
          }
        }
      }
      starIsland: file(relativePath: { eq: "star-island.jpg" }) {
        childImageSharp {
          fluid(quality: 80) {
            originalImg
          }
        }
      }
    }
  `);

  const items: any = [
    {
      title: 'ULTRA JAPAN',
      desc: 'Executive Committee, VVIP Operation, Production, Produce',
      imagePath: data.ultraJapan.childImageSharp.fluid.originalImg,
    },
    {
      title: 'STAR ISLAND',
      desc: 'Executive Committee, VVIP Operation, Production, Produce',
      imagePath: data.starIsland.childImageSharp.fluid.originalImg,
    },
  ];

  return (
    <Layout>
      <Seo pageTitle={description} pageLocation="/" />
      <AboutUs />
      <FeaturedWorks items={items} />
      <CompanyProfile />
      <ContactUs />
    </Layout>
  );
};

export default IndexPage;
