import * as React from 'react';
import styled from 'styled-components';
import ContentBlock from '../../molecules/ContentBlock';
import Br from '../../atoms/Br';
import P from '../../atoms/P';

const StyledP = styled(P)`
  ${({ theme }) => theme.media.sm`
    text-align: center;
  `};
`;

const AboutUs = () => {
  return (
    <ContentBlock
      title={
        <span>
          About&nbsp;
          <Br none="sm" />
          Us
        </span>
      }
    >
      <StyledP lang="en">
        Dare to challenge what is said to be impossible.&nbsp;
        <Br />
        The future is ahead. Rather than looking for reasons why you can't do it,&nbsp;
        <Br block="sm" />
        look for ways to do it.&nbsp;
        <Br />
        That leads to great potential. Everything becomes experience and knowledge.&nbsp;
        <Br />
        Accumulate know-how, improve abilities and face big barriers. Such "nou = know-how, ability,&nbsp;
        <Br />
        brain" gathered, Create unprecedented value and make the world more interesting.
      </StyledP>
      <StyledP last>
        不可能だといわれていることにあえて挑戦すること。&nbsp;
        <Br />
        その先に未来が待っている。&nbsp;
        <Br />
        出来ない理由を探すより、出来る方法を本気で探すこと。&nbsp;
        <Br />
        そのことが大きな可能性へとつながっていく。&nbsp;
        <Br />
        どんなことでも、それは経験となり、知識となっていく。&nbsp;
        <Br />
        ノウハウを積み上げ、能力を向上し、
        <Br none="sm" />
        また大きな壁に立ち向かっていく。&nbsp;
        <Br />
        そんな"nou＝ノウハウ、能力、脳”が集まって、&nbsp;
        <Br />
        今までにない価値を創造し、世界を面白くしていく。
      </StyledP>
    </ContentBlock>
  );
};

export default AboutUs;
