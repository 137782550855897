import * as React from 'react';
import styled from 'styled-components';
import ContentBlock from '../../molecules/ContentBlock';
import Br from '../../atoms/Br';

const Flex = styled.dl`
  display: flex;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spaces[5]};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  line-height: 1.8;

  ${({ theme }) => theme.media.sm`
    flex-direction: row;
    border-top: 1px solid #8d8d8d;
    font-size: ${theme.fontSizes[1]};
    padding: ${theme.spaces[2]} 0;
    margin-bottom: 0;
    line-height: 2;
  `};
`;

const Key = styled.dt`
  ${({ theme }) => theme.media.sm`
    width: 120px;
  `};
`;

const Valu = styled.dd`
  margin-left: 0;

  ${({ theme }) => theme.media.sm`
    flex: 1;
  `};
`;

const CompanyProfile = () => {
  return (
    <ContentBlock
      title={
        <span>
          Company&nbsp;
          <Br />
          Profile
        </span>
      }
    >
      <Flex>
        <Key>社名:</Key>
        <Valu>株式会社Nou</Valu>
      </Flex>
      <Flex>
        <Key>本社所在地:</Key>
        <Valu>150-0032 東京都渋谷区鶯谷町3-1 渋谷SUビル 801</Valu>
      </Flex>
      <Flex>
        <Key>TEL&FAX:</Key>
        <Valu>03-6416-5407 / 03-6416-5482</Valu>
      </Flex>
      <Flex>
        <Key>E-Mail:</Key>
        <Valu>promotion@nouinc.co.jp</Valu>
      </Flex>
      <Flex>
        <Key>設立:</Key>
        <Valu>2018年9月13日</Valu>
      </Flex>
      <Flex>
        <Key>代表取締役:</Key>
        <Valu>大西範一</Valu>
      </Flex>
      <Flex>
        <Key>主な業務内容:</Key>
        <Valu>イベント企画・制作、WEB制作、デザイン制作</Valu>
      </Flex>
      <Flex>
        <Key>主な実績:</Key>
        <Valu>
          ULTRA JAPAN
          <Br />
          実行委員会、VVIP運営、協賛ブース制作・運営、各種制作
          <Br />
          STAR ISLAND
          <Br />
          実行委員会、VIPエリア運営、各種制作
          <Br />
          東京2020
          <Br />
          自転車競技ロードレース テストイベント 実施制作、運営
        </Valu>
      </Flex>
      <Flex>
        <Key>主要取引先:</Key>
        <Valu>
          株式会社電通
          <Br />
          株式会社電通ライブ
          <Br />
          株式会社電通オペレーションズ
          <Br />
          株式会社ADKマーケティング・ソリューションズ
          <Br />
          株式会社東急エージェンシー
          <Br />
          エイベックス・エンタテインメント株式会社
          <Br />
          Activ8株式会社
          <Br />
          株式会社フロンティアインターナショナル
          <Br />
          ニホンキカク株式会社
          <Br />
          株式会社ドライブコミュニケーションズ
          <Br />
          株式会社シミズオクト
          <Br />
          株式会社セレスポ
          <Br />
          LeaR株式会社
          <Br />
          リコレリーサ株式会社
          <Br />
          株式会社クロスリング
        </Valu>
      </Flex>
      <Flex>
        <Key>大西範一:</Key>
        <Valu>
          CEO / Planner / Producer
          <Br />
          <Br none="sm" />
          1981年生まれ。イベント企画・制作を主に行い、企画～制作～現場まで幅広くこなす。
          マーケティング会社に勤めた経験もあり、ロジックの構築を得意とする。企業プロモー
          ションから音楽フェスなど多岐に渡るジャンルで年間100案件近くこなし、スピードと
          クオリティでも評価を得ている。また、フェスブームを巻き起こした世界最大級のダンス
          ミュージックフェスティバルULTRA JAPAN、伝統文化を新たな形に昇華し、未来へとつ なげていくSTAR
          ISLANDの立ち上げ段階から携わる。
        </Valu>
      </Flex>
    </ContentBlock>
  );
};

export default CompanyProfile;
