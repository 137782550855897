import * as React from 'react';
import ContentBlock from '../../molecules/ContentBlock';
import Cover from '../../molecules/Cover';
import Br from '../../atoms/Br';

interface FeaturedWorksProps {
  items: any;
}

const FeaturedWorks = ({ items }: FeaturedWorksProps) => {
  return (
    <ContentBlock
      fullWidth
      title={
        <span>
          Featured&nbsp;
          <Br />
          Works
        </span>
      }
    >
      <Cover items={items} />
    </ContentBlock>
  );
};

export default FeaturedWorks;
