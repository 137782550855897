import styled, { css } from 'styled-components';

export const inputStyle = css`
  width: 100%;
  padding: 0 ${({ theme }) => theme.spaces[1]};
  /* font-size: ${({ theme }) => theme.fontSizes[2]}; */
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.baseText[0]};
  background-color: ${({ theme }) => theme.colors.input.bg};
  border: 1px solid ${({ theme }) => theme.colors.input.border};
  border-radius: 4px;
  outline: none;
  box-shadow: none;
  transition: border ${({ theme }) => theme.transition};
  -webkit-appearance: none;

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.input.forcus.border};
  }
`;

const Input = styled.input`
  ${inputStyle}

  height: ${({ theme }) => theme.control.height};
`;

export default Input;
