import * as React from 'react';
import styled from 'styled-components';

import Br from '../../atoms/Br';
import Button from '../../atoms/Button';
import Input from '../../atoms/Input';
import Textarea from '../../atoms/Textarea';
import FormItem from '../../molecules/FormItem';
import ContentBlock from '../../molecules/ContentBlock';

const Form = styled.form``;

const ButtonBox = styled.div`
  text-align: center;
`;

const ContactUs = () => {
  return (
    <ContentBlock
      title={
        <span>
          Contact&nbsp;
          <Br />
          Us
        </span>
      }
    >
      <Form name="contact" method="POST" action="thanks" data-netlify="true" data-netlify-honeypot="bot-field">
        <Input type="hidden" name="form-name" value="contact" />
        <Input type="hidden" name="bot-field" />
        <FormItem title="Name" htmlFor="name" required>
          <Input type="text" id="name" name="name" required aria-required />
        </FormItem>
        <FormItem title="Tel" htmlFor="tel">
          <Input type="tel" id="tel" name="tel" />
        </FormItem>
        <FormItem title="mail" htmlFor="mail" required>
          <Input
            type="mail"
            id="mail"
            name="mail"
            pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
            required
            aria-required
          />
        </FormItem>
        <FormItem title="Comment" htmlFor="comment" required>
          <Textarea id="comment" name="comment" required aria-required />
        </FormItem>
        <ButtonBox>
          <Button type="submit" ariaLabel="Submit button">
            Submit
          </Button>
        </ButtonBox>
      </Form>
    </ContentBlock>
  );
};

export default ContactUs;
