import styled, { css } from 'styled-components';

const blockSm = css`
  display: none;
  ${({ theme }) => theme.media.sm`
    display: block;
  `}
`;

const blockMd = css`
  display: none;
  ${({ theme }) => theme.media.md`
    display: block;
  `}
`;

const blockLg = css`
  display: none;
  ${({ theme }) => theme.media.lg`
    display: block;
  `}
`;

const noneSm = css`
  ${({ theme }) => theme.media.sm`
    display: none;
  `}
`;

const noneMd = css`
  ${({ theme }) => theme.media.md`
    display: none;
  `}
`;

const noneLg = css`
  ${({ theme }) => theme.media.lg`
    display: none;
  `}
`;

const Br = styled.br<BrProps>`
  ${({ block }) => (block === 'sm' ? blockSm : block === 'md' ? blockMd : block === 'lg' ? blockLg : null)};
  ${({ none }) => (none === 'sm' ? noneSm : none === 'md' ? noneMd : none === 'lg' ? noneLg : null)};
`;

export interface BrProps {
  block?: 'sm' | 'md' | 'lg' | 'xl';
  none?: 'sm' | 'md' | 'lg' | 'xl';
}

export default Br;
